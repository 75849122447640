<template>
  <div class="w-100">
    <table class="table table-hovered table-striped table-bordered">
      <thead>
        <tr class="text-center">
          <th>#</th>
          <th>{{ $t('team') }}</th>
          <th>
            <img src="../../../assets/images/played.png" alt="Partidos jugados" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/goals.png" alt="Goles" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/assist.png" alt="Asistencias" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/yellow.png" alt="Amarillas" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/red.png" alt="Rojas" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/best.png" alt="Mejor jugador" class="img-icon-sm">
          </th>
        </tr>
      </thead>
      <tbody v-for="(tournament, ind) in record" :key="`tbody-${ind}`">
        <tr class="text-center">
          <td colspan="8">
            <router-link :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: tournament.tournament_slug } }">{{ tournament.tournament_name }}</router-link> | <router-link :to="{ name: 'DivisionHome', params: { lang: lang, console: console, divisionSlug: tournament.division_slug } }">{{ tournament.division_name }}</router-link>
          </td>
        </tr>
        <tr>
          <td class="text-right">{{ ind + 1 }}</td>
          <td>
            <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: tournament.team_slug } }">
              <img :src="tournament.custom_image_team" :alt="tournament.team_name" class="img-icon mr-2">
              <span>{{ tournament.team_name }}</span>
            </router-link>
          </td>
          <td class="text-center">{{ tournament.pjs }}</td>
          <td class="text-center">{{ tournament.goals }}</td>
          <td class="text-center">{{ tournament.assists }}</td>
          <td class="text-center">{{ tournament.yellows }}</td>
          <td class="text-center">{{ tournament.reds }}</td>
          <td class="text-center">{{ tournament.bests }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      record: [],
      slug: this.$route.params.playerSlug
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'playerFront'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_USER', '')
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const path = `${lang}/console/${console}/player/${this.slug}/record`
      this.$axios.get(path).then((response) => {
        this.record = response.data.data
      })
    }
  }
}
</script>
