var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('table',{staticClass:"table table-hovered table-striped table-bordered"},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t('team')))]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._l((_vm.record),function(tournament,ind){return _c('tbody',{key:`tbody-${ind}`},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"8"}},[_c('router-link',{attrs:{"to":{ name: 'TournamentHome', params: { lang: _vm.lang, console: _vm.console, tournamentSlug: tournament.tournament_slug } }}},[_vm._v(_vm._s(tournament.tournament_name))]),_vm._v(" | "),_c('router-link',{attrs:{"to":{ name: 'DivisionHome', params: { lang: _vm.lang, console: _vm.console, divisionSlug: tournament.division_slug } }}},[_vm._v(_vm._s(tournament.division_name))])],1)]),_c('tr',[_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(ind + 1))]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'TeamHome', params: { lang: _vm.lang, console: _vm.console, teamSlug: tournament.team_slug } }}},[_c('img',{staticClass:"img-icon mr-2",attrs:{"src":tournament.custom_image_team,"alt":tournament.team_name}}),_c('span',[_vm._v(_vm._s(tournament.team_name))])])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.pjs))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.goals))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.assists))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.yellows))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.reds))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tournament.bests))])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/played.png"),"alt":"Partidos jugados"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/goals.png"),"alt":"Goles"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/assist.png"),"alt":"Asistencias"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/yellow.png"),"alt":"Amarillas"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/red.png"),"alt":"Rojas"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('img',{staticClass:"img-icon-sm",attrs:{"src":require("../../../assets/images/best.png"),"alt":"Mejor jugador"}})])
}]

export { render, staticRenderFns }